import React from 'react';
import { useForm } from 'react-hook-form';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';
import Hero from '../components/rows/hero/index';

import Banner from '../images/hero-images/research-hero.jpg';

export default function PatientSurvey() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    fetch('/.netlify/functions/patient-survey', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => console.log(res));
    if (errors) {
      console.log(errors);
    }
  };

  const radioOptions = [
    {
      title:
        '1. The medical staff person I met with/spoke to (front desk staff, technician, nurse, physician assistant, or other) was friendly and knowledgeable and answered my questions.',
      key: 'medicalstaff',
    },
    {
      title: '2. I was given clear instructions to prepare for my appointment.',
      key: 'clearinstructions',
    },
    {
      title:
        '3. The medical staff person I met with (cardiologist, technician, or other) was friendly and knowledgeable and answered my questions.',
      key: 'doctor',
    },
    {
      title:
        '4. Did your experience with your PACE cardiologist meet or exceed your expectations?',
      key: 'cardiologistExpectations',
    },
    {
      title:
        '5. If you visited one of our clinics for cardiac testing, did that experience meet or exceed your expectations?',
      key: 'clinicExpectation',
    },
    {
      title: '6. Would you recommend PACE Cardiology to others?',
      key: 'recommend',
    },
  ];

  return (
    <Layout>
      <Seo title="Patient Feedback Survey" description="Feedback Survey." />
      <Hero title="Patient Feedback Survey" banner={Banner} />
      <Container className="my-52">
        <Row>
          <Col md={6} sm={12} className="mx-auto">
            <h2 className="text-center">
              <span className="text-red-300">Your </span> feedback matters
            </h2>
            <p className="text-center">
              At PACE Cardiology, we strive to provide the best possible care to
              our patients and we welcome your feedback.
            </p>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              type="text"
              name="First Name"
              {...register('First Name', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
              id="firstName"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              type="text"
              name="Last Name"
              id="lastName"
              {...register('Last Name', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              type="text"
              name="Email Address"
              {...register('Email Address', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
              id="email"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="date"
            >
              Date of consultation
            </label>
            <input
              type="datetime-local"
              name="Date of consultation"
              {...register('Date of consultation', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
              id="date"
            />
          </div>
          {radioOptions.map((radio, id) => (
            <div key={id}>
              <p>{radio.title}</p>
              {/* Yes */}
              <span className="radio">
                <label for={`${radio.key}Y`} htmlFor={`${radio.key}Y`}>
                  Yes
                </label>
                <input
                  {...register}
                  type="radio"
                  id={`${radio.key}Y`}
                  name={`${radio.key}`}
                  value="Yes"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </span>
              {/* No */}
              <span className="radio">
                <label for={`${radio.key}N`} htmlFor={`${radio.key}N`}>
                  No
                </label>
                <input
                  {...register}
                  type="radio"
                  id={`${radio.key}N`}
                  name={`${radio.key}`}
                  value="No"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </span>
              {/* NA */}
              <span className="radio">
                <label for={`${radio.key}NA`} htmlFor={`${radio.key}NA`}>
                  N/A
                </label>
                <input
                  {...register}
                  type="radio"
                  id={`${radio.key}NA`}
                  name={`${radio.key}`}
                  value="N/A"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </span>
            </div>
          ))}
          <div>
            <label htmlFor="textarea">
              7. We welcome your comments or questions – please feel free to
              share your input:
            </label>
            <textarea
              {...register('comments', {})}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
              id="textarea"
            />
          </div>
          <div className="button-wrapper">
            <button type="submit" className="form-button red-button">
              Submit Form
            </button>
          </div>
        </form>
      </Container>
    </Layout>
  );
}
